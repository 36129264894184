



















































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Dashboard extends Vue {
  private name = "Giles Tetteh";
  private drawer = true;
  private windowHeight = window.screen.height;

  private selectedItem = 0;

  onMenuClicked (action: any) {
    if (action && action.type === "link") {
      const link: any = action.link;
      this.$router.replace(link)
      .catch(err => {});
    }

    if (action && action.type === "function") {
      if (action.params) action.function(action.params);
      else action.function();
    }
  }

  get items () {
    const isSuper: boolean = this.$store.state.user ? this.$store.state.user.isSuper : false;
    const permissions: Array<string> = this.$store.state.user && this.$store.state.user.group ? this.$store.state.user.group.permissions : [];

    let list: Array<any> = []
    list.push({ text: 'Overview', icon: 'mdi-view-dashboard', action: { type: 'link', link: '/dashboard/overview' } });
    let headerSet = false;

    if (isSuper || permissions.includes("units:*")) {
      if (!headerSet) list.push({ text: 'MASTERS', subheader: true })
      list.push({ text: 'Units', icon: 'mdi-calculator', action: { type: 'link', link: '/dashboard/masters/unit' } })
      headerSet = true;
    }

    if (isSuper || permissions.includes("stock-groups:*")) {
      if (!headerSet) list.push({ text: 'MASTERS', subheader: true })
      list.push({ text: 'Stock Groups', icon: 'mdi-database', action: { type: 'link', link: '/dashboard/masters/stock-group' } });
      headerSet = true;
    }

    if (isSuper || permissions.includes("stock-items:*")) {
      if (!headerSet) list.push({ text: 'MASTERS', subheader: true })
      list.push({ text: 'Stock Items', icon: 'mdi-douban', action: { type: 'link', link: '/dashboard/masters/stock-item' } },);
      headerSet = true;
    }

    if (isSuper || permissions.includes("machines:*")) {
      if (!headerSet) list.push({ text: 'MASTERS', subheader: true })
      list.push({ text: 'Machines', icon: 'mdi-anvil', action: { type: 'link', link: '/dashboard/masters/machine' } })
      headerSet = true;
    }

    headerSet = false;

    if (isSuper || permissions.includes("vouchers:*")) {
      list = list.concat([
        { text: 'TRANSACTIONS', subheader: true },
        { text: 'Manufacturing', icon: 'mdi-fireplace', action: { type: 'link', link: '/dashboard/transactions/manufacturing' } }
      ]);
    }


    if (isSuper || permissions.includes("reports:*")) {
      list = list.concat([
        { text: 'REPORTS', subheader: true },
        { text: 'Day Book', icon: 'mdi-book', action: { type: 'link', link: '/dashboard/reports/daybook' } },
        { text: 'Stock Machine-wise', icon: 'mdi-fireplace', action: { type: 'link', link: '/dashboard/reports/machine-wise' } },
        { text: 'Machine Stock-wise', icon: 'mdi-scale-balance', action: { type: 'link', link: '/dashboard/reports/item-wise' } },
        { text: 'Item Produced List', icon: 'mdi-note-multiple', action: { type: 'link', link: '/dashboard/reports/item-list' } }
      ]);
    }

    headerSet = false

    if (isSuper || permissions.includes("settings:*")) {
      if (!headerSet) list.push({ text: 'ADMINISTRATION', subheader: true })
      list.push({ text: 'Settings', icon: 'mdi-cog-outline', action: { type: 'link', link: '/dashboard/settings' } })
      headerSet = true;
    }

    if (isSuper || permissions.includes("user-groups:*")) {
      if (!headerSet) list.push({ text: 'ADMINISTRATION', subheader: true })
      list.push({ text: 'User Groups', icon: 'mdi-account-group', action: { type: 'link', link: '/dashboard/security/group' } })
      headerSet = true;
    }

    if (isSuper || permissions.includes("users:*")) {
      if (!headerSet) list.push({ text: 'ADMINISTRATION', subheader: true })
      list.push({ text: 'Users', icon: 'mdi-account-tie', action: { type: 'link', link: '/dashboard/security/user' } })
      headerSet = true;
    }
    
    return list;

  }

  get profileItems () {
    const items = [
      {title: "Logout", action: { type: "function", function: this.logout }}
    ];
    return items;
  }

  logout () {
    this.$store.dispatch("logout", this.onLogout);
  }

  onLogout (e: any, d: any) {
    if (d) this.$router.replace("/").catch(err => {});
  }

  created(){
  }
}
